var LoginURL = 'https://mcdonaldsapi.portalonewifi.com/Authentication/';
var BaseURL = 'https://mcdonaldsapi.portalonewifi.com/api/Administration/';
var DashboardURL = 'https://mcdonaldsapi.portalonewifi.com/api/Dashboard/';
var AnalyticsURL = 'https://mcdonaldsapi.portalonewifi.com/api/Analytics/';
var CommonURL = 'https://mcdonaldsapi.portalonewifi.com/api/Common/';
var AdministrationURL = 'https://mcdonaldsapi.portalonewifi.com/api/Administration/';
var ReportsURL = 'https://mcdonaldsapi.portalonewifi.com/api/Reports/';
var LogsURL = 'https://mcdonaldsapi.portalonewifi.com/api/Log/';
var MarketingURL = 'https://mcdonaldsapi.portalonewifi.com/api/Marketing/';
var ActionAlertURL = 'https://mcdonaldsapi.portalonewifi.com/api/ActionAlert/';

export const envConfig = {
  LOGIN_API: `${LoginURL}`,
  ADMIN_API: `${BaseURL}`,
  DASHBOARD_API: `${DashboardURL}`,
  ANALYTICS_API: `${AnalyticsURL}`,
  COMMON_API: `${CommonURL}`,
  ADMINISTRATION_API: `${AdministrationURL}`,
  REPORTS_API: `${ReportsURL}`,
  LOGS_API: `${LogsURL}`,
  MARKETING_API: `${MarketingURL}`,
  ACTION_ALERT_API: `${ActionAlertURL}`,
};